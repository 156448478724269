<template>
  <div>
    <ol class="breadcrumb float-xl-right">
      <li class="breadcrumb-item">
        <router-link :to="{name:'dashboard'}">Dashboard</router-link>
      </li>
      <li class="breadcrumb-item"><a href="#">Bidbond Amendments</a></li>
    </ol>
    <h1 class="page-header">BIDBOND AMENDMENTS</h1>
    <panel noButton="true" title="BIDBOND AMENDMENTS">
      <loading v-if="!is_loaded" />
      <template v-else>
        <div
            class="row mb-3"
        >
          <div class="input-group col-md-4 mb-3">
            <input
                v-model="search"
                class="form-control py-2 border-right-0 border"
                type="search"
                placeholder="Search..."
            >
            <span class="input-group-append">
            <button
                class="btn btn-outline-secondary border-left-0 border"
                type="button"
            >
              <i class="fa fa-search" />
            </button>
          </span>
          </div>
        </div>
        <table
            v-if="bidbonds.length"
            class="table table-striped table-responsive-sm"
        >
          <thead>
          <tr>
            <th>#</th>
            <th>MD Ref</th>
            <th>Ref</th>
            <th>Original</th>
            <th>Changes</th>
            <th>User</th>
            <th>Date</th>
          </tr>
          </thead>
          <tbody>
          <template>
            <tr
                v-for="(bidbond, i) in bidbonds"
                :key="i"
            >
              <td>{{ i + pagination.from }}</td>
              <template v-if="bidbond.bidbond">
                <td>{{ bidbond.bidbond.reference }}</td>
                <td>{{ bidbond.bidbond.secret }}</td>
              </template>
              <template v-else>
                <td />
                <td />
              </template>
              <td>
                <div
                    v-for="(value,k) in
                    bidbond.original"
                    :key="k"
                >
                  <span>{{ k }} : </span>
                  <span>{{ value }}</span>
                </div>
              </td>
              <td>
                <div
                    v-for="(value,k) in
                    bidbond.changes"
                    :key="k"
                >
                  <span>{{ k }} : </span>
                  <span>{{ value }}</span>
                </div>
              </td>
              <td>{{ bidbond.username }}</td>
              <td>{{ bidbond.created_at }}</td>
            </tr>
          </template>
          </tbody>
        </table>
        <div
            v-else
            class="alert alert-warning"
        >
          <p>There are no bidbond amendments</p>
        </div>
        <pagination
            v-if="pagination.last_page > 1"
            :pagination="pagination"
            :offset="5"
            @paginate="fetchAmendments"
        />
      </template>
    </panel>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: null,
      bidbonds: [],
      is_loaded: false,
      pagination: {
        'current_page': 1,
        'last_page': 1,
        'from': 1
      }
    }
  },
  watch: {
    search(o, n) {
      if (!n && !o) return;
      this.fetchEntriesDebounced();
    },
  },
  mounted() {
    this.fetchAmendments();
  },
  methods: {
    fetchEntriesDebounced() {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.fetchAmendments()
      }, 2000)
    },
    fetchAmendments() {
      this.is_loaded = false;
      this.$axios.get('/api/v1/bid-bonds/amendments', {
        params: {
          page: this.pagination.current_page,
          search: this.search
        }
      }).then(response => {
        this.is_loaded = true;
        if (!response.data.data) {
          this.bidbonds = [];
          return;
        }
        this.bidbonds = response.data.data;
        this.pagination = this.pagination = (({total, per_page, current_page, last_page, from, to}) => ({
          total,
          per_page,
          current_page,
          last_page,
          from,
          to
        }))(response.data);
      });
    }
  }
}
</script>